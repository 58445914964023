#CreateAccountForm{
  margin: 0 auto;
  width: 35%;
  height: 75%;
  padding: 10px;
  border: 1px solid #043927;
  border-radius: 10px;
  background-color: #C4B581;
  margin-top: 10%;
}
header {
  height: 175px ;
  width: 100%;
  background-color: white;
  font-size: 250%;
  text-align: center;
  background-size: 150px;
  background-position: center;
  background-repeat: no-repeat;
  color: #ffffff;
  align-items: center;
}

.createAccountContents{
  align-items: center;
}

#createAccountError {
  display: none;
}

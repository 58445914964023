#loginForm{
    margin: 0 auto;
    width: 50%;
    padding: 10px;
    border: 1px solid #043927;
    border-radius: 10px;
    background-color: #C4B581;
    margin-top: 10%;

}
body{
   background-image: url(../Images/LoginPageImage.jpg);
   background-repeat: no-repeat;
   background-attachment: fixed;
   background-size: cover;
}
header {
    height: 150px;
    background-color: white;
    padding: 10px;
    font-size: 250%;
    text-align: center;
    background-image: url(/Images/Sacramento-State-University-logo_stacked.png);
    background-size: 150px;
    background-position: center;
    background-repeat: no-repeat;
    color: #ffffff;
    width: 100%;
    align-items: center;
}

p {
   text-decoration: underline;
}
h2 {
   text-align: right;
}

.loginContents{
   align-items: center;
}

#loginError {
   display: none;
}
 
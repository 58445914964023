ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
	background-color: #c4b581;
}

li {
	float: left;
}

li a {
	display: block;
	margin-bottom: 10px;
	color: black;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
	background-color: #043927;
	color: white;
}

#profileName {
	font-size: 20px;
	font-weight: bold;
	color: #043927;
	text-align: left;
}

#userMenu li {
	width: 100%; /* adjust */
}

#userMenu li button {
	width: 100%; /* adjust */
	height: 75px; /* adjust */
	color: black;

	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
	background-color: #c4b581;
}

#userMenu li button:hover {
	color: white;
	background-color: #043927;
}

.grid {
	display: flex;
	flex-direction: column;
}

.row {
	display: flex;
}

.column1 {
	width: 150px;
	padding: 10px;
}
.column2 {
	width: 100%;
	padding: 10px;
}
#iFrame {
	margin-left: 200px; /* adjust the value based on your needs */
	height: calc(100vh - 75px);
}
#profilePic {

	background-color: #043927;
}
#userName {
	background-color: #c4b581;
	align-items: flex-end;
}
#userMenu {
	float: left;
	position: fixed;
	height: 100%;
	width: 150px;
	background-color: #c4b581;
}

.container{
    padding: 30px;
    justify-content: center;
    background-color: #c4b581;
    font-size: 20px;
    text-align: center;
    margin: auto;
    width: 90%;
}

.filters {
    padding: 10px;
    justify-content: center;
    text-align: center;
}

.filterTableDiv{
    padding: 10px;
    text-align: center;
    background-color: white;
    margin: auto;
    border: 2px solid;
    align-items: center;
    justify-content: center;
    display: flex;
}

#filterTable {
    width: 100%;
}


#filterTableHeader{
    padding: 10px;
    font-weight: bold;
    font-size: 30px;
    background-color: #c4b581;
}

#filterTableItem{
    padding: 10px;
    text-align: center;
}

#filterLabel{
    padding: 10px;
    display: inline-block;
}

#filterTextbox{
    padding: 5px;
}

#filterDropdownMenu{
    padding: 5px;
    justify-content: center;
}

#filterHeader{
    font-weight: bold;
    font-size: 40px;
    padding: 10px;
}

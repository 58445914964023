.calendar {
  max-width: 2000px; /* Set the maximum width you want for the calendar */
  margin: 0 auto; /* Center the calendar horizontally */
  padding: 0px;
  position: relative;
}

.fc .fc-prev-button, .fc .fc-next-button {
  color: rgb(255, 255, 255); /* Change text color to black */
  background-color: #2c3e50; /* Optional: white background */
  border: 1px solid #2c3e50; /* Optional: black border */
}

.fc .fc-prev-button:hover, .fc .fc-next-button:hover {
  color: white; /* White text on hover */
  background-color: #1a252f; /* Black background on hover */
}

.custom-modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4); /* Black background with transparency */
}

/* Modal content */
.custom-modal-content {
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center; /* Center content */
}

/* Event details styling */
.event-details {
  margin-top: 20px;
}

.event-title {
  margin-bottom: 20px;
  font-size: 24px;
}

.event-text {
  font-size: 18px;
  margin-bottom: 10px; /* Add space between text and button */
  text-decoration: none; /* Remove underline */
}

/* Delete button */
.delete-button {
  margin-top: 20px; /* Add space between the text and delete button */
  background-color: #dc3545; /* Initial red color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Hover effect for delete button */
.delete-button:hover {
  background-color: #c82333; /* Darker red on hover */
}

/* Close button */
.close {
  color: #000000;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Form styling */
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-control {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn {
  background-color: #3738a7; /* Blue */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn:hover {
  background-color: #45a049; /* Green on hover */
}

.messageContainer{
    margin: auto;
    width: 90%;
    background-color: white;
    justify-content: center;
    padding: 10px;
    align-items: center;
}

.messageHeader{
    font-size:x-large;
    font-weight: bold;
    text-align: center;
}

#messageTable{
    width: 100%;
    margin: 5px;
}

#messageTableRow{
    width: 100%;
}

#messageTableHead{
    padding: 10px;
    border: 2px solid;
}

#messageTableData{
    padding: 10px;
}

